<template>
  <div class="content">
    <div class="conetnt-head">
      <div
        :style="check == -1 ? 'color:rgba(0, 163, 255, 1);' : ''"
        @click="checked(-1)"
      >
        <p>全部</p>
        <p :style="check == -1 ? 'background:rgba(0, 163, 255, 1);' : ''"></p>
      </div>
      <div
        :style="check == 0 ? 'color:rgba(0, 163, 255, 1);' : ''"
        @click="checked(0)"
      >
        <p>彩贝收入</p>
        <p :style="check == 0 ? 'background:rgba(0, 163, 255, 1);' : ''"></p>
      </div>
      <div
        :style="check == 1 ? 'color:rgba(0, 163, 255, 1);' : ''"
        @click="checked(1)"
      >
        <p>彩贝支出</p>
        <p :style="check == 1 ? 'background:rgba(0, 163, 255, 1);' : ''"></p>
      </div>
    </div>
    <template v-if="list.length > 0">
      <!-- 兑换列表 -->
      <div v-for="(item, index) in list" :key="index">
        <p>
          <span>{{ item.title }}</span>
          <span>{{time(item.ctime)}}</span>
        </p>
        <p>
          <span :style="item.type > 0 ? 'color: rgba(0, 163, 255, 1)' : ''"
            >{{ item.type > 0 ? "+" : "-" }}{{ item.formatCoin }}{{item.coinUnit}}</span
          >
        </p>
        <div></div>
      </div>
    </template>
    <div v-else class="jil">无更多记录</div>
  </div>
</template>
<script>
import caibei from "../../api/caibei";
import dayjs from "dayjs";
export default {
  data() {
    return {
      shibai: false,
      list: [],
      check: -1,
      page: 1,
    };
  },
  mounted() {
    const that = this;
    that.changeBalRecord(-1);
    window.addEventListener("scroll", function () {
      //加载更多
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight && scrollTop > 100) {
        //写后台加载数据的函数
        if(that.list.length< 20) return false;
        that.page++;
        if (that.check == -1) {
          that.changeBalRecord(-1);
        } else {
          that.changeBalRecord(that.check);
        }
      }
    });
  },
  methods: {
    checked(e) {
      this.page = 1;
      this.check = e;
      this.list = [];
      if (this.check == -1) {
        this.changeBalRecord(-1);
      } else {
        this.changeBalRecord(this.check);
      }
    },
    time(item) {
      return dayjs(item).format("YYYY-MM-DD HH:mm:ss");
    },
    changeBalRecord(res) {
      let data;
      if (res == -1) {
        data = {
          page: this.page,
          pageSize: 20,
        };
      } else {
        data = {
          page: this.page,
          pageSize: 20,
          type: res,
        };
      }
      caibei.coinRecords(data).then((res) => {
        this.list.push(...res.list);
      });
    },
  },
};
</script>
<style scoped>
.content .conetnt-head {
  border-bottom: 1px solid #eeeeee;
  display: flex;
  height: 40px;
}
.content .conetnt-head div {
  width: 40px;
  height: 100%;
  background: #ffffff;
  position: relative;
  padding: 0;
  justify-content: center;
  white-space: nowrap;
}
.content .conetnt-head div p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content .conetnt-head div p:nth-child(2) {
  height: 4px;
  margin-top: -4px;
  width: 20px;
  border-radius: 2px;
}
.content {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
}
.content div {
  height: 70px;
  display: flex;
  padding: 0 14px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  flex-wrap: wrap;
  position: relative;
}
.content div p:nth-child(1) span {
  display: flex;
  justify-content: left;
}
.content div p {
  width: 50%;
}
.content div p span {
  display: flex;
  justify-content: flex-end;
}
.content div div {
  width: calc(100% - 28px);
  height: 1px;
  margin: 0 auto;
  background: #eeeeee;
  position: absolute;
  bottom: 0;
}
.content div p span:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.content div p span:nth-child(1) {
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content div p:nth-child(2) span {
  text-align: right;
}
.content .jil {
  justify-content: center;
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>